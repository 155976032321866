@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700');

body {
    font-family: $font-primary;
}

.product-image:hover {
    border-color: $primary;
}

.selected-product-image-color {
    border-color: $primary ;
}

.selected-product-image-mobile-color {
    border-color: $primary ;
}
h1 {
	font-size: 36px;
}

.taradel-blue {
	color: $primary;
}

.alert-promo-with-close-button {
	background-color: #5AB2D6;
}


.alert-promo-with-close-button > .btn-close {
	filter: invert(1) grayscale(100%) brightness(200%)
}
